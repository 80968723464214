import React, { Component, Suspense } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import './scss/style.scss'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const RegisterPage = React.lazy(() => import('./views/pages/register-Pages/RegisterPages'))
const RegisterPageCognito = React.lazy(() => import('./views/pages/register-Pages/RegisterPagesCognito'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))
const ResetPassword = React.lazy(() => import('./views/pages/ResetPassword/ResetPassword'))
const Page200ResetPassword = React.lazy(() => import('./views/pages/Page200ResetPassword/Page200ResetPassword'))
// Email App
const EmailApp = React.lazy(() => import('./views/apps/email/EmailApp'))

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <Suspense fallback={loading}>
          <Routes>
            <Route exact path="/login" name="Login Page" element={<Login />} />
            <Route exact path="/register" name="Register Page" element={<RegisterPage />} />
            <Route exact path="/registerCognito" name="Register Page" element={<RegisterPageCognito />} />

            <Route exact path="/404" name="Page 404" element={<Page404 />} />
            <Route exact path="/500" name="Page 500" element={<Page500 />} />
            <Route path="/apps/email/*" name="Email App" element={<EmailApp />} />
            <Route path="*" name="Home" element={<DefaultLayout />} />
            <Route path="/reset-password" name="Reset Password" element={<ResetPassword />} />
            <Route exact path="/200" name="Page 200" element={<Page200ResetPassword />} />

          </Routes>
        </Suspense>
      </BrowserRouter>
    )
  }
}

export default App
