import { combineReducers } from 'redux'

import { authentication } from './authentication.reducer'
// import { registration } from './registration.reducer';
// import { users } from './users.reducer';
// import { alert } from './alert.reducer';

const initialState = {
  sidebarShow: 'responsive',
  asideShow: false,
}

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return { ...state, ...rest }
    default:
      return state
  }
}

const rootReducer = combineReducers({
  authentication,
  nav: changeState,
  // registration,
  // users,
  // alert
})

export default rootReducer
